let currentSlot = null;

function aLink(originalEl) {
  let el = originalEl;
  while (el) {
    if (el.tagName === 'A') {
      return el.href;
    }
    el = el.parentNode;
  }
  return '';
}

function initContainerDomElement() {
  const containerId = 'bnChildContainer';
  let node = document.getElementById(containerId);
  if (!node) {
    node = document.createElement('div');
    node.id = containerId;
    node.addEventListener('click', (e) => {
      if (e.target.classList.contains('close')) {
        if (currentSlot && currentSlot.close) {
          currentSlot.childClose();
        } else {
          node.style.display = 'none';
          node.innerHTML = '';
        }
      } else if (currentSlot) {
        currentSlot.registerClick(aLink(e.target));
      }
    });
    const parent = document.body;
    parent.insertBefore(node, parent.firstChild);
  }
  return node;
}

class BannersChildContainer {
  constructor() {
    this.node = null;
  }

  initContainer() {
    if (!this.node) {
      // Can not be in constructor. Sometimes firefox calls it
      // before document.body exists
      this.node = initContainerDomElement();
    }
  }

  open(slot, html, className) {
    this.initContainer();
    currentSlot = slot;
    this.node.setAttribute('class', '');
    this.classAdd(className);
    this.node.innerHTML = html;
    this.show();
    return this;
  }

  classAdd(className) {
    if (className) {
      this.node.classList.add(className);
    }
    return this;
  }

  show() {
    this.node.style.display = 'block';
  }

  hide() {
    this.node.style.display = 'none';
    this.node.innerHTML = '';
    this.node.setAttribute('class', '');
  }
}

const childContainer = new BannersChildContainer();

export default childContainer;
