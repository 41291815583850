import childContainer from './childContainer';

export default function animatePopup(originalSlot, storage) {
  const slot = originalSlot;
  window.pn5366 = true;
  if (window.$modalRoomsPopup) {
    // PN-5366 block rooms_popup.htm
    try {
      window.$modalRoomsPopup.close();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  const banner = slot.bannerGet('media');
  const html = `<div class="inner"><a class="close"></a>${banner.html}</div>`;

  function childOpen() {
    if (window.PopupIsOpened) {
      return;
    }
    window.PopupIsOpened = true;

    childContainer
      .open(slot, html, 'popup-banner')
      .classAdd(banner.tag && banner.tag.class);

    slot.loaded(banner.id);

    // pop-ups are shown only occasionally
    const now = Math.round((new Date()).getTime() / 1000);
    storage.storageSet('bnLastPopupT', now);
  }

  slot.open = childOpen;
  slot.status = 'popup prepared';
}
