function BannersViewsLimits(strLoad) {
  this.data = {};
  this.now = Math.floor((new Date()).getTime() / 1000);
  this.canViewAfter = null;
  if (strLoad) {
    // parse strload
    const s = strLoad.split('|');
    if (s.length) {
      // expires after 2 months
      const earlestTime = this.now - 86400 * 60;
      for (let i = 0; i < s.length; i += 1) {
        const a = s[i].split(':');
        const bId = a[0];
        const lastView = parseInt(a[1], 10);
        if (!Number.isNaN(lastView) || parseInt(lastView, 10) > earlestTime) {
          this.data[bId] = lastView;
        }
      }
    }
  }

  this.canView = function canView(bID, limit) {
    this.canViewAfter = null;
    if (limit) {
      const a = limit.split(':');
      const bLimit = parseInt(a[1], 10);
      if (!Number.isNaN(bLimit) && bLimit > 0) {
        let period = 0;
        switch (a[0]) {
          case 'd':
            period = 86400;
            break;
          case 'h':
            period = 3600;
            break;
          case 'o':
            period = 86400 * 61;
            break;
          default:
            // eslint-disable-next-line no-console
            console.log('Unexpected value for a[0]:', a[0]);
        }

        const nextTime = Math.floor(period / bLimit);
        if (this.data[bID] && nextTime && (this.data[bID] + nextTime) > this.now) {
          // console.log('can after ' + (this.data[bID] + nextTime - this.now)+ ' s');
          this.canViewAfter = (this.data[bID] + nextTime - this.now);
          return false;
        }
      }
    }
    return true;
  };

  this.view = function view(bID, limit) {
    if (limit) {
      this.data[bID] = this.now;
    }
  };

  this.save = function save() {
    const h = [];
    Object.keys(this.data).forEach((i) => {
      if (this.data[i] != null) {
        h.push(`${i}:${this.data[i]}`);
      }
    });
    return h.join('|');
  };
}

export default BannersViewsLimits;
