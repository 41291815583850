/* eslint-disable no-console */
import { debug } from './bannersDebug';
import bannerHTML from './bannersListItem';
import slotOpenController from './bannersSlotOpenController';

let bannersList;

/**
 * Gets an alternative data-mzone if it should be used.
 *
 * Some slots may have separate zone for mobiles (data-mzone attribute), which
 * should be shown for narrow viewports instead of a regular data-zone.
 * PN-10749.
 *
 * @param {HTMLElement}   node  HTML element of the banner slot.
 * @returns {string|null}       Returns data-mzone value if it exists and should
 *     be shown by viewport width, and returns null otherwise.
 */
function preferMzone(node) {
  function windowWidthGet() {
    return window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
  }
  const useMzone = windowWidthGet() <= 480;
  const mzone = useMzone
    ? node.getAttribute('data-mzone')
    : null;

  return mzone;
}

function getElementWidth(el) {
  const style = el.currentStyle || window.getComputedStyle(el);
  const width = parseInt(el.clientWidth, 10)
    - parseInt(style.paddingLeft, 10)
    - parseInt(style.paddingRight, 10);
  return width;
}

function appendTimestampArg(originalUrl) {
  let url = originalUrl;
  const noCacheArg = '_=';
  const noArgExists = url.indexOf(`?${noCacheArg}`) === -1
    && url.indexOf(`&${noCacheArg}`) === -1;
  if (noArgExists) {
    const separator = url.indexOf('?') === -1 ? '?' : '&';
    url = url + separator + noCacheArg + (new Date().getTime());
  }

  return url;
}

class BannersSlot {
  constructor(node, engine) {
    this.node = node;
    this.engine = engine;
    this.free = true;
    this.clicked = false;
    this.zone = node.getAttribute('data-zone');
    this.animation = node.getAttribute('data-animation');
    this.status = '';
    this.banner_id = null;
    this.banner_data = null;

    this.bannersQueue = engine.queue;
    bannersList = engine.banners;

    if (engine.settings.debug && window.document.location.search.indexOf('bnZone=') > -1) {
      if (document.location.search.indexOf(`bnZone=${this.zone}`) === -1) {
        // disable zones not interested in
        this.free = false;
      }
    }
  }

  insertBanner() {
    // PN-10749 use data-mzone in mobile viewport
    this.zone = preferMzone(this.node) || this.zone;

    debug(`**${this.zone} **`);
    if (!this.isWaitingForFill()) {
      return;
    }
    this.free = false;
    const queue = this.queue();
    if (!queue.length) {
      this.node.setAttribute('data-ready', 'noexbanners');
      return;
    }

    const bId = queue[0];
    debug(`display-banner:${bId}`);
    const banner = this.engine.banners.itemGet(bId);
    debug(`${bId} banner cbm: ${banner.cbm}`);

    this.node.setAttribute('data-ready', bId);
    this.node.innerHTML = '';
    this.engine.banners.markAsShown(bId);

    this.status = 'preparing';
    this.banner_id = bId;
    this.banner_data = banner;
    this.engine.animations.show(this);
    if (this.status === 'ready') {
      this.loaded(this.banner_id);
      this.show();
    }
    slotOpenController.queueOpen(this);
  }

  queue() {
    let slotQueue = this.bannersQueue.getSlotQueue(this.zone);
    const fnQueueValue = this.node.getAttribute('data-func');
    if (fnQueueValue) {
      // select banners separately for this zone
      try {
        const fnQueue = Function(`return ${fnQueueValue}`)();
        const customQueue = fnQueue(this.engine.banners, this);
        if (typeof (customQueue) === 'object') {
          debug('queue_func result: ', customQueue);
          slotQueue = customQueue;
        }
      } catch (e) {
        debug('data-func failed!');
        debug(e);
      }
    }
    return slotQueue;
  }

  bannerGet(no, trackChild) {
    const variant = no === 'media2' ? 'media2' : 'media';
    let { zone } = this;
    if (trackChild) {
      zone += ':CHILD';
    }
    return bannerHTML(this.engine.settings.templates, this.banner_data, variant, zone);
  }

  show() {
    if (this.zone === 'pn2013:mobile:footer') {
      // PN-7509 close button
      const btnCloseHtml = '<button onclick="this.parentNode.style.display=\'none\'" class="close" role="button" aria-label="Close"></button>';
      this.node.innerHTML += btnCloseHtml;
    }

    this.node.style.display = '';

    if (this.node.getAttribute('data-ad-element')) {
      const el = document.querySelector(this.node.getAttribute('data-ad-element'));
      if (el) {
        el.style.display = 'block';
      }
    }
    if (this.node.getAttribute('data-toggle-element')) {
      // PN-8303 should hide subscribe widget
      const el = document.querySelector(this.node.getAttribute('data-toggle-element'));
      if (el) {
        el.style.display = 'none';
      }
    }
  }

  notifyAsEmpty() {
    bannersList.markAsEmpty(this.banner_id);
    this.loaded(this.banner_id, 'E');
    // lets give some time
    setTimeout(() => this.engine.fillSlots(), 500);
  }

  isWaitingForFill() {
    let maxWidth = this.node.getAttribute('data-max-width');
    if (maxWidth) {
      // separate zone for mobile PN-4509
      const nodeWidth = getElementWidth(this.node.parentNode);
      maxWidth = parseInt(maxWidth, 10);
      if (!nodeWidth) maxWidth = -1;
      if (maxWidth && maxWidth < nodeWidth) {
        this.status = 'data-max-width  prevented';
        return false;
      }
    }
    return true;
  }

  childOpen() {
    slotOpenController.open(this);
  }

  childClose() {
    slotOpenController.close(this);
  }

  loaded(bId, viewType) {
    this.viewType = typeof (viewType) === 'undefined' ? '' : viewType;
    this.banner_id = bId;
    this.free = this.viewType === 'E';
    const trackerId = `${this.banner_id}-${this.zone}${this.viewType === '' ? '' : `-${this.viewType}`}`;
    const banner = bannersList.itemGet(bId);
    const trackOnlyLoaded = banner && banner.confirmed_impressions;
    if (this.viewType === 'L' || !trackOnlyLoaded) {
      this.engine.trackEvent(trackerId);
      this.engine.viewsLimits.view(this.banner_id, banner.vLimit);
      if (banner.viewsURL) {
        const url = appendTimestampArg(banner.viewsURL);
        (new Image(1, 1)).src = url;
      }
      // this.viewType = 'LV';
      // visibilityHandler();
    }
    if (!this.free) {
      // PN-8162
      const otherCloseStr = this.node.getAttribute('data-other-close');
      if (otherCloseStr) {
        if (!window[otherCloseStr]) {
          window[otherCloseStr] = true;
        } else if (typeof (window[otherCloseStr].close) === 'function') {
          window[otherCloseStr].close();
          debug(`${otherCloseStr} closed by ${this.zone}`);
        }
      }

      this.catchClicks(this.node);
    }
  }

  catchClicks(el) {
    const onClick = (e) => {
      function aLink(linkEl) {
        let link = linkEl;
        while (link) {
          if (link.tagName === 'A') {
            return link.href;
          }
          link = link.parentNode;
        }
        return '';
      }
      this.registerClick(aLink(e.target));
    };

    if (!el.hasAttribute('data-listener')) {
      el.setAttribute('data-listener', 'true');
      el.addEventListener('click', onClick);
    }
  }

  registerClick(clickedUrl) {
    if (this.node.hasAttribute('data-clicked') || clickedUrl.length < 5) {
      return;
    }
    this.node.setAttribute('data-clicked', 'true');

    const { settings } = this.engine;
    const url = `${settings.serverURL}b-go2.php?sid=${settings.sID}${settings.urlTrackingArgs}`;

    const formData = new FormData();
    formData.append('z', this.zone);
    formData.append('cbid', this.banner_id);

    fetch(url, {
      body: formData,
      method: 'post',
    })
      .then((resp) => resp.text())
      .then((data) => {
        console.log(data);
      })
      .catch((e) => console.log('Fetch error:', e));
  }
}

export default BannersSlot;
