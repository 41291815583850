import repeatFnUntilTrue from '../utils/repeatFnUntilTrue';
import BannersEngine from './bannersEngine';

class Banners {
  constructor(settings, data) {
    this.settings = settings;
    this.data = data;
    this.self = null;
    this.initBannersEngine();
  }

  initBannersEngine() {
    if (this.self && this.self.interval) {
      clearInterval(this.self.interval);
    }
    this.self = new BannersEngine(this.settings, this.data);
  }

  fillSlots() {
    this.self.fillSlots();
  }
}

function bannersDataLoaded() {
  if (window.bannersOptions && window.bnData) {
    window.Banners = new Banners(window.bannersOptions, window.bnData);
    return true;
  }
  return false;
}

repeatFnUntilTrue(bannersDataLoaded);

document.addEventListener('turbo:load', () => {
  if (window.Banners) {
    window.Banners.initBannersEngine();
  }
});
