function detectDevice() {
  switch (true) {
    case /Android/i.test(navigator.userAgent): return 4;
    case /iPad/i.test(navigator.userAgent): return 16;
    case /iPhone|iPod/i.test(navigator.userAgent): return 2;
    case /webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent): return 8;
    case /iris|3g_t|windows ce|opera mobi|windows phone|XBLWP7/i.test(navigator.userAgent): return 8;
    default: return 1;
  }
  /*  //audrius: useful code
    var isMobile = window.matchMedia("only screen and (max-width: 760px)");
    if (isMobile.matches) {
    //Conditional script here
    }
  */
}

function BannersSettings(bannerSettings, bnData, storage) {
  const settings = bannerSettings;
  settings.serverURL = bnData.serverURL;
  settings.templates = bnData.templates;
  settings.sID = bnData.sID;
  settings.token = bnData.token;
  if (!settings.device) {
    settings.device = detectDevice();
  }
  // page views viewed
  let pageViewsCount = storage.sessionGet('bnPageViewsCount');
  pageViewsCount = pageViewsCount ? parseInt(pageViewsCount, 10) : 0;
  storage.sessionSet('bnPageViewsCount', 1 + pageViewsCount);
  settings.pageViewsCount = pageViewsCount;

  settings.lastPopupTime = storage.storageGet('bnLastPopupT');

  settings.urlTrackingArgs = ''
  + `&tk=${encodeURIComponent(settings.token)
  }&d=${encodeURIComponent(settings.device)
  }&c=${encodeURIComponent(settings.geoTarget)
  }&url=${encodeURIComponent(window.location.pathname)}`;
  return settings;
}

export default BannersSettings;
