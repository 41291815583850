import StorageWrapper from './storageWrapper';
import { debugSetup } from './bannersDebug';
import BannersSettings from './bannersSettings';
import BannersViewsLimits from './bannersViewsLimits';
import BannersList from './bannersList';
import BannersFilter from './bannersFilter';
import BannersSlot from './bannersSlot';
import BannersAnimations from './bannersAnimations';
import BannersQueues from './bannersQueue';

function detectNewSlots(engine) {
  const nodes = document.getElementsByClassName('virgutis');
  let node;
  let slot;
  for (let i = 0; i < nodes.length; i += 1) {
    node = nodes[i];
    if (!node.getAttribute('data-slot')) {
      node.setAttribute('data-slot', 1);
      slot = new BannersSlot(node, engine);
      if (slot.free) {
        engine.slots.push(slot);
      }
    }
  }
  return engine.slots.slots;
}

function clickUrlAdjustment(settings) {
  let argsAppend = settings.urlTrackingArgs;
  if (window.pn5558) {
    argsAppend += '&pn5558=1';
  }

  return function urlAppend(originalBanner) {
    const banner = originalBanner;
    if (banner.url) {
      banner.url += argsAppend;
    }
    return banner;
  };
}

const storage = new StorageWrapper();
let trackV = [];

class BannersEngine {
  constructor(settings, bnData) {
    this.settings = BannersSettings(settings, bnData, storage);
    debugSetup(settings);

    this.viewsLimits = new BannersViewsLimits(storage.storageGet('bnLimitViews'));
    this.banners = new BannersList(bnData.banners, storage);
    this.slots = [];

    const fnFilter = BannersFilter(this);
    this.banners.filterApply(fnFilter);
    this.banners.walk(clickUrlAdjustment(this.settings));

    this.queue = new BannersQueues(this);
    this.animations = new BannersAnimations(storage);

    detectNewSlots(this);
    this.queue.detectExclusiveCampaign(this.slots);

    // $(window).on('DOMContentLoaded load resize scroll', visibilityHandler);
    this.fillSlots();

    this.submitEventsLog();
    this.interval = setInterval(() => this.submitEventsLog(), 3000);
  }

  fillSlots() {
    detectNewSlots(this);
    this.slots.forEach((slot) => {
      if (slot.free) {
        slot.insertBanner();
      }
    });

    // track viewed banners
    // visibilityHandler();
  }

  trackEvent(event) {
    trackV.push(event);
  }

  submitEventsLog() {
    if (trackV.length < 1) {
      return;
    }

    this.banners.saveEmptyBanners();

    const h = this.viewsLimits.save();
    if (h) {
      storage.storageSet('bnLimitViews', h);
    }

    const viewsArg = window.btoa
      ? `&be=${encodeURIComponent(window.btoa(trackV.join('|')))}`
      : `&b=${encodeURIComponent(trackV.join('|'))}`;
    const { settings } = this;
    const url = `${settings.serverURL}b-view.php?sid=${settings.sID}${viewsArg}${settings.urlTrackingArgs}`;

    trackV = [];

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    document.getElementsByTagName('body')[0].appendChild(s);
  }
}

export default BannersEngine;
