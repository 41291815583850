let isDebugOn = false;

function debugSetup(settings) {
  isDebugOn = !!settings.debug;
}

function debug(...args) {
  if (isDebugOn) {
    // eslint-disable-next-line no-console
    console.debug(...args);
  }
}

export { isDebugOn, debug, debugSetup };
