import { debug } from './bannersDebug';

function BannersExclusiveCampaigns(slots, bannersList) {
  this.campaigns_ids = [];
  this.bannersList = bannersList;

  this.empty = function isEmpty() {
    return this.campaigns_ids.length === 0;
  };

  /**
    * Applies exclusive campaign filter to the queue of the slot.
    *
    * Removes not matching banners from queue if exclusive campaign is detected.
    *
    * @param {number[]}  queue  Array of banners ids
    *
    * @return {number[]} Filtered or unchanged queue array of banners ids.
  */
  this.filterSlotQueue = function filterQueue(queue) {
    if (this.empty()) {
      return queue;
    }
    const newQueue = [];
    for (let i = 0; i < queue.length; i += 1) {
      const bannerId = queue[i];
      const banner = this.bannersList.itemGet(bannerId);
      const campaignId = banner.cbm.split('-')[0];
      if (this.campaigns_ids.indexOf(campaignId) !== -1) {
        newQueue.push(bannerId);
      }
    }

    if (newQueue.length > 1) {
      // less times shown banners has higher priority
      const { bannersList } = this;

      const fnSort = function (aId, bId) {
        const a = bannersList.itemGet(aId).isShown || 0;
        const b = bannersList.itemGet(bId).isShown || 0;
        return a - b;
      };

      newQueue.sort(fnSort);
    }

    return newQueue;
  };

  /**
     * Searches for active exclusive campaign.
     *
     * Loops through active slots and looks at priority attribute among queued
     * banners.
     * PN-8888 added: exclusive campaigns with "Show only in" has higher priority
     * than other Exclusive campaigns.
     * PN-8963 added: all exclusive campaigns with "Show only in" can be shown
     * in the page, but only one without "Show only in".
     *
     * @param {ids[]}    ids   Array of banners id
     * @param {bannersList[]}  bannersList  Array of banners items
     *
     * @return {number[]} Array of campaign ids.
  */
  function findExclusiveCampaigns(ids, bannersList) {
    const exclusiveCampaigns = {
      uri: [],
      none: [],
    };

    ids.forEach((bannerId) => {
      const banner = bannersList.itemGet(bannerId);
      const priority = typeof (banner.priority) !== 'undefined'
        ? parseInt(banner.priority, 10)
        : 99;

      if (priority === 5) {
        const level = banner.uri_target ? 'uri' : 'none';
        const campaignId = banner.cbm.split('-')[0];
        if (exclusiveCampaigns[level].indexOf(campaignId) === -1) {
          exclusiveCampaigns[level].push(campaignId);
        }
        debug(`*** EXCLUSIVE CAMPAIGN (${level}) detected: ${banner.cbm}`);
      }
    });

    return exclusiveCampaigns.uri.length > 0
      ? exclusiveCampaigns.uri
      : exclusiveCampaigns.none.slice(0, 1);
  }

  this.campaigns_ids = findExclusiveCampaigns(slots, bannersList);
  debug('*** EXCLUSIVE CAMPAIGNS selected:', this.campaigns_ids);
}

export default BannersExclusiveCampaigns;
