import { debug } from './bannersDebug';

function openAfterSeconds(slot) {
  let seconds = slot.open_after || slot.banner_data.AppearAfter || 0;
  seconds = parseInt(seconds, 10);

  return seconds;
}

function openChildHandle(slot) {
  function fnOpenSlot() {
    window.pn5366 = true;
    if (window.$modalRoomsPopup) {
      // PN-5366 block rooms_popup.htm
      try {
        window.$modalRoomsPopup.close();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
    // TODO: track Child open event

    slot.open();
  }

  function openOnExit() {
    document.body.removeEventListener('mouseleave', openOnExit);
    fnOpenSlot();
  }

  const appearAfter = openAfterSeconds(slot);
  const appearOn = slot.banner_data.AppearOn;

  debug('Child Opening...', slot.zone, slot.banner_id, appearAfter);

  if (appearAfter) {
    // after some seconds
    setTimeout(fnOpenSlot, appearAfter * 1000);
  } else if (appearOn === 'exit') {
    // on exit

    document.body.addEventListener('mouseleave', openOnExit);
  } else if (appearOn === 'scroll') {
    // on scroll
    const popupScrollWaiting = setInterval(() => {
      const { body } = document;
      const html = document.documentElement;
      const docheight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const scrollTop = window.pageYOffset
        || (document.documentElement
        || document.body.parentNode
        || document.body).scrollTop;
      const winheight = window.innerHeight
        || (document.documentElement
        || document.body).clientHeight;
      // below is rewritten:
      // scrollPercent = scrollTop / (docheight - winheight) >= 50%;
      if ((2 * scrollTop + winheight - docheight) >= 0) {
        clearInterval(popupScrollWaiting);
        fnOpenSlot();
      }
    }, 1000);
  } else {
    // display immediately
    fnOpenSlot();
  }
}

class BannersSlotOpenController {
  constructor() {
    this.reserved = false;
  }

  queueOpen(slot) {
    if (!slot.open || slot.open_after === -1) {
      return;
    }
    debug('Child append', slot.zone, slot.banner_id, slot.open_after);
    if (!this.reserved) {
      openChildHandle(slot);
    }
    this.reserved = true;
  }

  open(slot) {
    if (!slot.open) {
      return;
    }
    slot.open();
  }

  close(slot) {
    if (!slot.close) {
      return;
    }
    slot.close();
  }
}

const slotOpenController = new BannersSlotOpenController();

export default slotOpenController;
