export default function animateWallpaper(originalSlot) {
  const slot = originalSlot;
  const banner = slot.bannerGet('media');
  const banner2 = slot.bannerGet('media2');
  let { html } = banner;
  if (banner2) {
    let bgsrc = banner2.img.src;
    const bgcolor = (banner.tag && banner.tag.bgcolor) || (banner2.tag && banner2.tag.bgcolor);

    const isFirstBigger = (banner.width > banner2.width)
      || (banner.width === banner2.width
      && banner.height > banner2.height);
    if (isFirstBigger) {
      // the first banner is bigger, swap them
      html = banner2.html;
      bgsrc = banner.img.src;
    }
    if (bgsrc) {
      const bgElement = document.documentElement;
      if (bgcolor) {
        bgElement.style.backgroundColor = bgcolor;
      }
      bgElement.style.backgroundImage = `url('${bgsrc}')`;
      bgElement.classList.add('hasWallpaper');
      bgElement.addEventListener('click', function onClick(e) {
        if (e.target === this) {
          slot.registerClick(banner.url);
          if (banner.self) {
            window.location.href = banner.url;
          } else {
            window.open(banner.url);
          }
        }
      });
    }
  }
  slot.node.innerHTML = html;
  slot.status = 'ready';
}
