import { debug } from './bannersDebug';

function setInnerHTML(originalNode, html) {
  const node = originalNode;
  node.innerHTML = html;

  function scriptNodeClone(scriptNode) {
    const script = document.createElement('script');
    script.text = scriptNode.innerHTML;
    for (let i = 0; i < scriptNode.attributes.length; i += 1) {
      const attr = scriptNode.attributes[i];
      script.setAttribute(attr.name, attr.value);
    }
    return script;
  }

  const scriptNodes = node.getElementsByTagName('SCRIPT');
  for (let i = 0; i < scriptNodes.length; i += 1) {
    const scriptNode = scriptNodes[i];
    scriptNode.parentNode.replaceChild(scriptNodeClone(scriptNode), scriptNode);
  }
}

function createIframe(slot, html, iframeTitle) {
  const iframe = document.createElement('iframe');
  iframe.style.height = 0;
  iframe.style.width = '100%';
  iframe.marginWidth = 0;
  iframe.marginHeight = 0;
  iframe.frameBorder = 0;
  iframe.width = 0;
  iframe.height = 0;
  iframe.className = `bnid_${slot.banner_id}`;
  iframe.title = iframeTitle || '';
  slot.node.appendChild(iframe);

  function onIframeLoaded() {
    // need to display for height purpose
    // slot.node.style.display = "";
    let height;
    try {
      height = iframe.contentWindow.document.body.scrollHeight;
    } catch (e) {
      debug(`Error in iframe javascript height detection in ${slot.banner_id}-${slot.zone} :`, e.message);
      height = -1;
    }
    // for stats, register impression
    debug(`iframe ${slot.banner_id} loaded. height:${height}`);
    if (height > 20) {
      slot.loaded(slot.banner_id, 'L');
      slot.catchClicks(iframe.contentWindow.document.body);
      iframe.style.height = `${height}px`;
    } else {
      debug(`iframe ${slot.banner_id} is EMPTY`);
      iframe.style.display = 'none';
      slot.notifyAsEmpty();
    }
  }

  iframe.addEventListener('load', () => setTimeout(onIframeLoaded, 100));
  iframe.src = 'about:blank';
  const content = ''
    + '<html>'
    + '<title>8</title>'
    + '<meta http-equiv="content-type" content="text/html; charset=utf-8">'
    + '<base target="_blank">'
    + '<style>body{margin:0;padding:0;text-align:center;line-height:4px}</style>'
    + `<body>${
      html
    }</body></html>`;
  try {
    const doc = iframe.contentWindow.document;
    doc.write(content);
    doc.close();
    debug(`iframe ${slot.banner_id} writen`);
  } catch (e) {
    debug('Error in iframe javascript:', e.message);
  }
}

export { setInnerHTML, createIframe };
