import { debug } from './bannersDebug';
import { setInnerHTML, createIframe } from './bannersHtmlLoader';
import animateWallpaper from './animations/animateWallpaper';
import animatePopup from './animations/animatePopup';

function BannersAnimations(storage) {
  function animateBasic(originalSlot) {
    const slot = originalSlot;
    const banner = slot.bannerGet('media');
    if (banner.type === 'html' && slot.zone !== 'sponsored:post' && banner.iframe) {
      // html per iframe
      createIframe(slot, banner.html, banner.alt);
    } else {
      if (banner.type === 'html') {
        setInnerHTML(slot.node, banner.html);
      } else {
        slot.node.innerHTML = banner.html;
      }
      slot.status = 'ready';
    }
  }

  function displayBanner(slot) {
    const banner = slot.bannerGet('media');
    if (banner.animation) {
      debug(`animation:${banner.animation}`);
    }
    if (banner.height && !banner.animation) {
      slot.node.style.setProperty('--aspect-ratio-h', banner.height);
      slot.node.style.setProperty('--aspect-ratio-w', banner.width);
    }
    switch (banner.animation) {
      case 'wallpaper':
        return animateWallpaper(slot);
      case 'popup':
        return animatePopup(slot, storage);
      case 'scrolled-in-article':
        slot.node.classList.add('scrolled-in-article');
        // no break
      // eslint-disable-next-line no-fallthrough
      default:
        return animateBasic(slot);
    }
  }

  this.show = function show(slot) {
    displayBanner(slot);
  };
}

export default BannersAnimations;
