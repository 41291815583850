function tplParse(originalTpl, vars) {
  let tpl = originalTpl;
  Object.keys(vars).forEach((q) => {
    tpl = tpl.replace(new RegExp(`\\{${q}\\}`, 'g'), vars[q]);
  });
  return tpl;
}

function searchBannerTag(originalHtml) {
  let html = originalHtml;
  const attr = {};
  const bannerTags = html.match(/<banner([^>]*)>/ig);
  if (bannerTags) {
    bannerTags.forEach((tag) => {
      html = html.replace(tag, '');
      const tmp = document.implementation.createHTMLDocument('');
      tmp.body.innerHTML = tag;
      const { children } = tmp.body;
      Array.prototype.forEach.call(children, (el) => {
        for (let i = 0; i < el.attributes.length; i += 1) {
          attr[el.attributes[i].nodeName] = el.attributes[i].nodeValue;
        }
      });
    });
  }
  return {
    html,
    attr,
  };
}

function BannerListItem(templates, banner, mediaName, zone) {
  let html = '';
  let bannerTag = null; let width = false; let height = false; let btype = false; let
    iframe = true;
  const clickUrl = banner.url.replace('{zone}', zone);
  const media = banner[mediaName];
  const arg = [];
  if (html === '' && media && media.img) {
    // if img exist
    arg.url = clickUrl;
    arg.alt = typeof (banner.alt) === 'string' ? banner.alt : '';
    arg.target = banner.self ? '_self' : '_blank';
    width = media.img.w;
    arg.w = width;
    height = media.img.h;
    arg.src = media.img.src;
    const imgTemplate = banner.animation === 'scrolled-in-article'
      ? 'image:scrolled-in-article'
      : 'image';
    html = tplParse(templates[imgTemplate], arg);
    btype = 'image';
    if (media.html) {
      const bannerHTML = searchBannerTag(media.html);
      bannerTag = bannerHTML.attr;
    }
  }
  // the rest html part
  if (html === '' && media && media.html) {
    const bannerHTML = searchBannerTag(media.html);
    bannerTag = bannerHTML.attr;
    arg.html = bannerHTML.html;
    arg.url = clickUrl;
    const timestamp = (new Date()).getTime();
    arg.html = arg.html.replace(/\[(timestamp|TIMESTAMP|CACHEBUSTER)\]/g, timestamp);
    arg.html = arg.html.replace(/((referrer_url=)INSERT_SERVER_MACRO)/g, `$2${encodeURIComponent(document.location.href)}`);
    html = tplParse(templates.html, arg);
    if (bannerHTML.attr.width) width = bannerHTML.attr.width;
    if (bannerHTML.attr.height) height = bannerHTML.attr.height;
    if (bannerHTML.attr.iframe === 'no') iframe = false;
    btype = 'html';
  }
  if (banner.tracking_pixel) {
    const timestamp = (new Date()).getTime();
    html += banner.tracking_pixel.replace(/\[(timestamp|TIMESTAMP|CACHEBUSTER)\]/g, timestamp);
  }

  return {
    id: banner.id,
    animation: banner.animation || '',
    img: (media && media.img) || null,
    alt: banner.alt || '',
    url: clickUrl,
    html,
    tag: bannerTag,
    width: parseInt(width, 10) || null,
    height: parseInt(height, 10) || null,
    type: btype,
    iframe,
    self: banner.self,
  };
}

export default BannerListItem;
