function BannersList(bannersList, storage) {
  this.bannersList = bannersList;
  this.empty_banners = [];

  this.itemGet = function itemGet(id) {
    let banner = {};
    if (this.bannersList[id]) {
      // banner = Object.assign({}, this.bannersList[id]);
      banner = this.bannersList[id];
    }

    return banner;
  };

  this.filterApply = function filterApply(callback) {
    Object.keys(this.bannersList).forEach((bId) => {
      const banner = this.bannersList[bId];
      if (!callback(banner)) {
        this.bannersList[bId] = false;
      }
    });
  };

  this.walk = function walk(callback) {
    Object.keys(this.bannersList).forEach((bId) => {
      const banner = this.bannersList[bId];
      this.bannersList[bId] = callback(banner);
    });
  };

  this.getList = function getList() {
    return this.bannersList;
  };

  this.markAsShown = function markAsShown(id) {
    // we need a count for exclusive campaigns
    const count = this.bannersList[id].isShown || 0;
    this.bannersList[id].isShown = 1 + count;
  };

  this.isShown = function isShown(id) {
    return !!this.bannersList[id].isShown;
  };

  this.markAsEmpty = function markAsEmpty(id) {
    this.empty_banners.push(id);
  };

  this.saveEmptyBanners = function saveEmptyBanners() {
    if (this.empty_banners.length > 1) {
      const value = this.empty_banners.join(',');
      storage.storageSet('bnEmpty', value);
    }
  };

  function loadEmptyBanners() {
    let emptyBanners;
    const value = storage.storageGet('bnEmpty');
    const now = Math.round((new Date()).getTime() / 1000);
    if (value) {
      emptyBanners = value.split(',');
      if (emptyBanners[0] && emptyBanners[0] < now) {
        // expired already
        emptyBanners = [180 + now];
      }
    } else {
      emptyBanners = [180 + now];
    }
    return emptyBanners;
  }

  // constructor
  this.empty_banners = loadEmptyBanners();

  const banners = this.bannersList;
  this.empty_banners.forEach((id, index) => {
    if (index > 0 && banners[id]) {
      banners[id].isEmpty = 1;
    }
  });
}

export default BannersList;
